import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroWithOverlay from "../components/Hero/HeroWithOverlay";
import EstatePlanningGuide from "../components/Repeating/EstatePlanningGuide";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
// import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import FAQs from "../components/Repeating/FAQ";

const Page = ({ data }) => {
   const heroImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   const [refStep1, inViewStep1] = useInView({ threshold: 0.5 }),
      [refStep2, inViewStep2] = useInView({ threshold: 0.5 }),
      [refStep3, inViewStep3] = useInView({ threshold: 0.5 }),
      [refStep4, inViewStep4] = useInView({ threshold: 0.5 });

   const animationStep1 = useAnimation(),
      animationDot1 = useAnimation(),
      animationDotBorder1 = useAnimation(),
      animationStep2 = useAnimation(),
      animationDot2 = useAnimation(),
      animationDotBorder2 = useAnimation(),
      animationStep3 = useAnimation(),
      animationDot3 = useAnimation(),
      animationDotBorder3 = useAnimation(),
      animationStep4 = useAnimation(),
      animationDot4 = useAnimation(),
      animationDotBorder4 = useAnimation();

   useEffect(() => {
      if (inViewStep1) {
         animationStep1.start("visible");
         animationDot1.start("visibleDot");
         animationDotBorder1.start("visibleBorder");
      }
      if (inViewStep2) {
         animationStep2.start("visible");
         animationDot2.start("visibleDot");
         animationDotBorder2.start("visibleBorder");
      }
      if (inViewStep3) {
         animationStep3.start("visible");
         animationDot3.start("visibleDot");
         animationDotBorder3.start("visibleBorder");
      }
      if (inViewStep4) {
         animationStep4.start("visible");
         animationDot4.start("visibleDot");
         animationDotBorder4.start("visibleBorder");
      }
   }, [
      animationStep1,
      animationDot1,
      animationDotBorder1,
      animationStep2,
      animationDot2,
      animationDotBorder2,
      animationStep3,
      animationDot3,
      animationDotBorder3,
      animationStep4,
      animationDot4,
      animationDotBorder4,
      inViewStep1,
      inViewStep2,
      inViewStep3,
      inViewStep4,
   ]);

   const variants = {
      visible: {
         opacity: 1,
         x: "0px",
         transition: { duration: 1 },
      },
      hidden: {
         opacity: 0,
      },
      visibleDot: {
         backgroundColor: "#4186C3",
         transition: { duration: 1 },
      },
      visibleBorder: {
         opacity: 1,
         width: "1.75rem",
         height: "1.75rem",
         transition: { duration: 1 },
      },
      hiddenBorder: {
         opacity: 0,
         width: "0rem",
         height: "0rem",
      },
      hiddenLeft: {
         opacity: 0,
         x: "-100px",
      },
      hiddenRight: {
         opacity: 0,
         x: "100px",
      },
   };
   return (
      <Layout>
         <SearchEngineOptimization
            title="Estate Planning for Small Business Owners | Conscious Legal"
            description="Conscious Legal offers top estate planning for business owners that is relationship-driven, not transaction-focused. Schedule a consultation today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroWithOverlay
            image={heroImages}
            heading={["Estate Planning", <br className="hidden md:block" />, " for Business Owners & Families"]}
            subtext="Avoid court and conflict, keep your affairs private, and ensure your wishes are carried out."
            nextBackgroundColor="bg-primary-50"
         ></HeroWithOverlay>

         <section className="bg-primary-50 pb-20 md:pb-28">
            <div className="container">
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="About Alanna’s Mission" />
                  </div>
                  <div>
                     <h2>A Top Estate Planning Attorney for Your Needs</h2>
                     <p className="text-mobile-lg md:text-lg">
                        You love your family, and want to make sure things are as easy as possible for them, no matter what happens. You want to pass
                        on what you've worked hard for your whole life, and do it in a way that is filled with ease and grace.
                     </p>

                     <p className="text-mobile-lg md:text-lg">
                        Your wealth isn’t measured just by the size of your bank account, but by the wellbeing and care of the people you leave
                        behind.
                     </p>

                     <p className="text-mobile-lg md:text-lg mb-0">
                        You may be single, married, just getting started in your career, or looking back on a life well lived. The one common
                        denominator is that you truly and deeply care about the people in your life. We'll help you make things as easy as possible
                        for them, so they'll always know how much you cared.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <section className="pt-12 md:pt-16 mb-24 md:mb-36">
            <div className="container">
               <header>
                  <h2>How Estate Planning Can Help</h2>
               </header>
               <div className="grid md:grid-cols-2 gap-x-6 md:gap-x-16 lg:gap-x-24 gap-y-6 md:gap-y-8">
                  <div>
                     <p className="font-display text-mobile-7xl md:text-7xl text-primary-900 mb-3">1.</p>
                     <h3 className="heading-four mb-3">Manage Your Legal Affairs</h3>
                     <p className="mb-0">
                        Take charge of your legal life planning so the money and property you leave behind are inherited by individuals you
                        identified, distributed according to your wishes, and protected for generations.
                     </p>
                  </div>
                  <div>
                     <p className="font-display text-mobile-7xl md:text-7xl text-primary-900 mb-3">2.</p>
                     <h3 className="heading-four mb-3">Grant Authority</h3>
                     <p className="mb-0">
                        We ensure your wishes are continuously honored and the people YOU (and not a court) designate, make health and personal care
                        decisions on your behalf, in the event you are incapacitated.
                     </p>
                  </div>
                  <div>
                     <p className="font-display text-mobile-7xl md:text-7xl text-primary-900 mb-3">3.</p>
                     <h3 className="heading-four mb-3">Protect Your Valuables</h3>
                     <p className="mb-0">
                        Avoid years of litigation and expenses from a drawn out court process that would make life difficult for your family and
                        others you cherish. Simplify matters and give yourself serenity.
                     </p>
                  </div>
                  <div>
                     <p className="font-display text-mobile-7xl md:text-7xl text-primary-900 mb-3">4.</p>
                     <h3 className="heading-four mb-3">Create a Succession Plan</h3>
                     <p className="mb-0">
                        Designing a succession or continuity plan involves passing on more than just money. Our attorneys will construct your
                        preferred plan so you can leave behind a true legacy of love.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-26 overflow-hidden">
            <div className="container">
               <header className="md:text-center mb-16 md:mb-20">
                  <h2>How It Works</h2>
               </header>

               <div className="relative">
                  <div className="hidden md:block w-px bg-black bg-opacity-30 h-full absolute left-0 right-0 mx-auto"></div>
                  <div className="relative grid md:grid-cols-2 md:gap-x-24 lg:gap-x-32 gap-y-6 items-center mb-12 md:mb-20">
                     <div className="absolute left-0 right-0 hidden md:flex items-center justify-center">
                        <motion.span
                           className="bg-gray-400 rounded-full w-4 h-4 absolute"
                           ref={refStep1}
                           animate={animationDot1}
                           variants={variants}
                        />
                        <motion.span
                           className="border-2 border-solid border-primary-900 rounded-full absolute"
                           ref={refStep1}
                           animate={animationDotBorder1}
                           initial="hiddenBorder"
                           variants={variants}
                        />
                     </div>
                     <motion.div ref={refStep1} animate={animationStep1} initial="hiddenLeft" variants={variants}>
                        <GatsbyImage image={data.howItWorks1.childImageSharp.gatsbyImageData} alt="Family Wealth Planning" />
                     </motion.div>

                     <motion.div ref={refStep1} animate={animationStep1} initial="hiddenRight" variants={variants}>
                        <h3>1. Family Wealth Planning Session</h3>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           We’ll get acquainted, review your current circumstances, identify your goals and concerns, and design a plan together to be
                           sure that everything will happen exactly how you want.
                        </p>
                     </motion.div>
                  </div>

                  <div className="relative grid md:grid-cols-2 md:gap-x-24 gap-y-6 items-center mb-12 md:mb-20">
                     <div className="absolute left-0 right-0 hidden md:flex items-center justify-center">
                        <motion.span
                           className="bg-gray-400 rounded-full w-4 h-4 absolute"
                           ref={refStep2}
                           animate={animationDot2}
                           variants={variants}
                        />
                        <motion.span
                           className="border-2 border-solid border-primary-900 rounded-full absolute"
                           ref={refStep2}
                           animate={animationDotBorder2}
                           initial="hiddenBorder"
                           variants={variants}
                        />
                     </div>
                     <motion.div className="order-2 md:order-1" ref={refStep2} animate={animationStep2} initial="hiddenLeft" variants={variants}>
                        <h3>2. Plan Review Meeting</h3>
                        <p className="text-mobile-lg md:text-lg">
                           Congratulations, your legal documents are ready to be signed! At your Plan Review Meeting, we'll present each document,
                           review what it means, and remind you why you made your selections. We'll also make sure you're ready to get them signed and
                           notarized. From that point forward, your family and assets are protected.
                        </p>
                        <p className="text-mobile-lg md:text-lg mb-0">It's a big moment! Champagne optional. ;)</p>
                     </motion.div>

                     <motion.div className="order-1 md:order-2" ref={refStep2} animate={animationStep2} initial="hiddenRight" variants={variants}>
                        <GatsbyImage image={data.howItWorks2.childImageSharp.gatsbyImageData} alt="Plan Review Meeting" />
                     </motion.div>
                  </div>

                  <div className="relative grid md:grid-cols-2 md:gap-x-24 gap-y-6 items-center mb-12 md:mb-20">
                     <div className="absolute left-0 right-0 hidden md:flex items-center justify-center">
                        <motion.span
                           className="bg-gray-400 rounded-full w-4 h-4 absolute"
                           ref={refStep3}
                           animate={animationDot3}
                           variants={variants}
                        />
                        <motion.span
                           className="border-2 border-solid border-primary-900 rounded-full absolute"
                           ref={refStep3}
                           animate={animationDotBorder3}
                           initial="hiddenBorder"
                           variants={variants}
                        />
                     </div>
                     <motion.div ref={refStep3} animate={animationStep3} initial="hiddenLeft" variants={variants}>
                        <GatsbyImage fadeIn={false} loading="eager" image={data.howItWorks3.childImageSharp.gatsbyImageData} alt="Final Meeting" />
                     </motion.div>
                     <motion.div ref={refStep3} animate={animationStep3} initial="hiddenRight" variants={variants}>
                        <h3>3. Final Meeting</h3>
                        <p className="text-mobile-lg md:text-lg">
                           During our Final Meeting, we'll ensure your assets are titled correctly, and review the letters we've prepared to send to
                           the people named for important roles in your plan.
                        </p>
                        <p className="text-mobile-lg md:text-lg">
                           Then, we'll focus on capturing your most valuable asset – your insights, values, stories, and experiences.
                        </p>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           You'll record your Legacy Interview with us – the first of many you're invited to record and add to your online Legacy
                           Library. We capture the unique things that make you, "you," so they can be preserved and passed on to those you love.
                        </p>
                     </motion.div>
                  </div>

                  <div className="relative grid md:grid-cols-2 md:gap-x-24 gap-y-6 items-center">
                     <div className="absolute left-0 right-0 hidden md:flex items-center justify-center">
                        <motion.span
                           className="bg-gray-400 rounded-full w-4 h-4 absolute"
                           ref={refStep4}
                           animate={animationDot4}
                           variants={variants}
                        />
                        <motion.span
                           className="border-2 border-solid border-primary-900 rounded-full absolute"
                           ref={refStep4}
                           animate={animationDotBorder4}
                           initial="hiddenBorder"
                           variants={variants}
                        />
                     </div>
                     <motion.div className="order-2 md:order-1" ref={refStep4} animate={animationStep4} initial="hiddenLeft" variants={variants}>
                        <h3>4. Continuous Document Review</h3>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           We'll meet on an annual (VIP Members) or tri-annual (included in all our plans) basis, to ensure your plan stays up to date
                           as your life, assets, and the law change. We're also available at no additional cost for calls and emails about your estate
                           plan, to ensure it will work when your family needs it most.
                        </p>
                     </motion.div>
                     <motion.div className="order-1 md:order-2" ref={refStep4} animate={animationStep4} initial="hiddenRight" variants={variants}>
                        <GatsbyImage image={data.howItWorks4.childImageSharp.gatsbyImageData} alt="Continuous Document Review" />
                     </motion.div>
                  </div>
               </div>
            </div>
         </section>

         <Testimonials headingLevel="h2" />

         <EstatePlanningGuide className="py-16 md:py-28" headingLevel="h2" />

         <About headingLevel="h2" />

         <CallToAction headingLevel="h2" />

         <FAQs className="md:pt-16 pb-16 md:pb-24" headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Estate Planning_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Estate Planning_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/estate-planning/1.0 Hero - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "services/estate-planning/1.0 Hero - mobile.png" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/estate-planning/2.0 estate intro - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks1: file(relativePath: { eq: "services/estate-planning/3.0 step 1.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks2: file(relativePath: { eq: "services/estate-planning/3.1 step 2 - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks3: file(relativePath: { eq: "services/estate-planning/3.2 step 3 - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks4: file(relativePath: { eq: "services/estate-planning/3.3 step 4 - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Download from "../Form/Download";

const WhyUs = ({ className, headingLevel }) => {
   const data = useStaticQuery(graphql`
      {
         guide: file(relativePath: { eq: "repeating/estate-planning-guide/free-estimate-planning-guide.jpg" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
            }
         }
      }
   `);

   const HeadingTag = headingLevel;

   return (
      <section className={`${className || ""}`}>
         <div className="container">
            <div className="grid md:grid-cols-2 gap-y-5 md:gap-x-10 lg:gap-x-20 items-center">
               <div>
                  <GatsbyImage image={data.guide.childImageSharp.gatsbyImageData} alt="Free Estate Planning Guide" />
               </div>
               <div>
                  <HeadingTag>Free Estate Planning Guide</HeadingTag>
                  <p>Our free guide helps you save valuable time and money by avoiding common mistakes when selecting an estate planning attorney.</p>
                  <Download />
               </div>
            </div>
         </div>
      </section>
   );
};

export default WhyUs;
